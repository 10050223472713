import React, { useState, useRef, Suspense, useEffect } from 'react';
import { OrbitControls, Torus } from 'drei'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import { Canvas, extend, useFrame, useLoader } from 'react-three-fiber'
import { a, useSpring } from 'react-spring/three'
import { Controls, useControl } from 'react-three-gui'
import { TextureLoader } from 'three'
import './App.css';

function City() {
  const ref = useRef()
  const gltf = useLoader(GLTFLoader, '/scene.gltf')
  return (
    <primitive
      castShadow
      receiveShadow
      object={gltf.scene}
      position={[0, 0, 0]}
    />
  )
}


function Scene({ color = "gold" }) {

  const ref = useRef()

  return (
    <>
      {/* <ambientLight intensity={1} /> */}
      <directionalLight
        castShadow
        angle={Math.PI / 4}
        intensity={.5}
        position={[0, 2000, -1000]}
      />
      <Suspense fallback={null}>
        <City />
      </Suspense>
      <OrbitControls />
    </>
  )
}

function App() {
  return (
    <>
      <Canvas
        shadowMap
        camera={{
          position: [0, 100, -1000], near: 100, far: 10000000
        }}
      >
        <Scene />
      </Canvas>
    </>
  );
}

export default App;
